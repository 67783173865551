import { combineReducers } from "redux";

import General from "./generalReducer";
import ConnectWalletReducer from "./ConnectWalletReducer";
import LoaderReducer from "./loaderReducer";
import StakingReducer from "./stakingReducer";
import MarketReducer from "./marketReducer";

export default combineReducers({
  General,
  LoaderReducer,
  ConnectWalletReducer,
  StakingReducer,
  MarketReducer
});
