import { useSelector, useDispatch } from "react-redux";
import {
  NavBarContainer,
  NavList,
  LinkItems,
  MobileNavToggleContainer,
  MobileNavToggleBar,
  MobileNavContainer,
  MobileNavList,
  MobileNav
} from "./partialstyles";
import ConnectWallet from "../wallets/connectwallet";
import { togglethemeswitch, toggleMobileButton } from "../methods/actions";

const NavBar = () => {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.General.theme);
  const toggleButton = useSelector(state => state.General.toggleButton);

  const handleClick = () => {
    dispatch(togglethemeswitch(!theme));
  };

  return (
    <NavBarContainer className="container">
      <a href="https://wicrypt.com/" rel="noreferrer" target="_blank">
        {" "}
        {theme ? (
          <img src="/assets/logodark.svg" alt="logo" />
        ) : (
          <img src="/assets/Group.svg" alt="logo" />
        )}
      </a>
      <MobileNav>
        <LinkItems onClick={handleClick}>
          {theme ? (
            <img src="assets/sun.svg" alt="sun" />
          ) : (
            <img src="assets/moon.svg" alt="moon" />
          )}
        </LinkItems>
        <MobileNavToggleContainer
          onClick={() => dispatch(toggleMobileButton(!toggleButton))}
        >
          <MobileNavToggleBar />
          <MobileNavToggleBar active={toggleButton} />
          <MobileNavToggleBar />
        </MobileNavToggleContainer>
      </MobileNav>

      {toggleButton ? (
        <MobileNavContainer>
          <MobileNavList>
            <LinkItems>
              <ConnectWallet />
            </LinkItems>
          </MobileNavList>
        </MobileNavContainer>
      ) : (
        ""
      )}
      <NavList>
        <LinkItems>
          <ConnectWallet />
        </LinkItems>
        <LinkItems onClick={handleClick}>
          {theme ? (
            <img src="assets/sun.svg" alt="sun" />
          ) : (
            <img src="assets/moon.svg" alt="moon" />
          )}
        </LinkItems>
      </NavList>
    </NavBarContainer>
  );
};

export default NavBar;
