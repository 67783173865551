import React, { useEffect } from "react";
import {
  CardContainer,
  Description,
  ValueDetail,
  DescriptionSubValue,
  DescriptionContainer,
  WalletAddress,
  CardTitle,
  ViewOption,
  StakingDiv,
  HideContainer,
} from "./stakingstyles.js";
import { ButtonState } from "../reusables/button";
import truncateAddress from "../methods/utils/truncate-address";
import {
  getFormDetails,
  staketogglemodal,
  getAvailableBalance,
  getMarketQuote,
  getAllStakingIds,
  getActionLimit,
  getlockPeriod,
  getPendingRewards,
} from "../methods/actions";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommaswithoutdecimals } from "../methods/helper";
import ClaimableRewards from "./claimablerewards.js";
import ConnectWallet from "../wallets/connectwallet";

const StakingBalance = () => {
  const dispatch = useDispatch();
  const userStaked = useSelector((state) => state.StakingReducer.userStaked);
  const address = useSelector((state) => state.ConnectWalletReducer.address);
  const availableBalance = useSelector(
    (state) => state.General.availableBalance
  );
  const hidenumbers = useSelector((state) => state.General.hidenumbers);
  const usdQuote = useSelector((state) => state.MarketReducer.usdQuote);
  const tokenAddress = useSelector((state) => state.General.tokenAddress);
  const theme = useSelector((state) => state.General.theme);
  const ids = useSelector((state) => state.StakingReducer.ids);
  const ownerAddress = useSelector(
    (state) => state.ConnectWalletReducer.address
  );
  useEffect(() => {
    dispatch(getAvailableBalance(tokenAddress));
    dispatch(getMarketQuote());
    dispatch(getAllStakingIds());
    dispatch(getActionLimit());
    dispatch(getlockPeriod());
    dispatch(getPendingRewards());
  }, [dispatch, tokenAddress, ownerAddress]);

  return (
    <CardContainer>
      <StakingDiv>
        <CardTitle>My WNT Staking</CardTitle>
        <ViewOption>
          {" "}
          {hidenumbers ? (
            <HideContainer
              onClick={() =>
                dispatch(
                  getFormDetails({
                    props: ["hidenumbers"],
                    value: !hidenumbers,
                  })
                )
              }
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213Z"
                  stroke={theme ? "#E5B910" : "#25346A"}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.45801 12C3.73201 7.943 7.52301 5 12 5C16.478 5 20.268 7.943 21.542 12C20.268 16.057 16.478 19 12 19C7.52301 19 3.73201 16.057 2.45801 12Z"
                  stroke="#25346A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <p> Un-hide all numbers </p>
            </HideContainer>
          ) : (
            <HideContainer
              onClick={() =>
                dispatch(
                  getFormDetails({
                    props: ["hidenumbers"],
                    value: !hidenumbers,
                  })
                )
              }
            >
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.875 16.825C12.2569 16.9419 11.6291 17.0005 11 17C6.52203 17 2.73203 14.057 1.45703 10C1.8003 8.9081 2.32902 7.88346 3.02003 6.971M8.87803 7.879C9.44069 7.31634 10.2038 7.00025 10.9995 7.00025C11.7952 7.00025 12.5584 7.31634 13.121 7.879C13.6837 8.44166 13.9998 9.20478 13.9998 10.0005C13.9998 10.7962 13.6837 11.5593 13.121 12.122M8.87803 7.879L13.121 12.122M8.87803 7.879L13.12 12.12M13.121 12.122L16.412 15.412M8.88003 7.88L5.59003 4.59M5.59003 4.59L2.00003 1M5.59003 4.59C7.20239 3.54957 9.08113 2.9974 11 3C15.478 3 19.268 5.943 20.543 10C19.8391 12.2305 18.3774 14.1446 16.411 15.411M5.59003 4.59L16.411 15.411M16.411 15.411L20 19"
                  stroke={theme ? "#E5B910" : "#25346A"}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p> Hide all numbers </p>
            </HideContainer>
          )}
        </ViewOption>
      </StakingDiv>
      <div className="col-lg-3 col-sm-3">
        <WalletAddress> {truncateAddress(address)}</WalletAddress>
      </div>

      <DescriptionContainer>
        <div>
          <Description> TOTAL STAKED</Description>
          <ValueDetail>
            {" "}
            {hidenumbers
              ? "-----"
              : numberWithCommaswithoutdecimals(userStaked) + " WNT"}{" "}
            <DescriptionSubValue>
              {" "}
              {hidenumbers ? (
                ""
              ) : (
                <i className="fa fa-arrows-h" aria-hidden="true" />
              )}
              {hidenumbers
                ? ""
                : `$${numberWithCommaswithoutdecimals(usdQuote * userStaked)}`}
            </DescriptionSubValue>{" "}
          </ValueDetail>
        </div>

        <div>
          <Description> AVAILABLE IN WALLET</Description>
          <ValueDetail>
            {" "}
            {hidenumbers
              ? "-----"
              : numberWithCommaswithoutdecimals(availableBalance) + " WNT"}{" "}
            <DescriptionSubValue>
              {" "}
              {hidenumbers ? (
                ""
              ) : (
                <i className="fa fa-arrows-h" aria-hidden="true" />
              )}
              {hidenumbers
                ? ""
                : `$${numberWithCommaswithoutdecimals(
                    usdQuote * availableBalance
                  )}`}
            </DescriptionSubValue>{" "}
          </ValueDetail>
        </div>
      </DescriptionContainer>
      {address ? <ClaimableRewards /> : null}
      {address ? (
        <div className="row mb-3 padbtn">
          <ButtonState
            fontSize={"0.8rem"}
            buttonClass="primary"
            label={"Stake"}
            onClick={() => dispatch(staketogglemodal(1))}
          />
        </div>
      ) : (
        <div className="row t-center">
          <div clasName="col-lg-12">
            <ConnectWallet />
          </div>
        </div>
      )}
      {ids.length > 0 ? (
        <div className="row padbtn">
          <ButtonState
            fontSize={"0.8rem"}
            buttonClass="secondary"
            label={"UnStake"}
            onClick={() => dispatch(staketogglemodal(2))}
          />
        </div>
      ) : (
        <div> </div>
      )}
    </CardContainer>
  );
};

export default StakingBalance;
