const _const = {
  // General actions
  TOGGLE_THEME: "TOGGLE_THEME",
  TOGGLE_MODAL: "TOGGLE_MODAL",
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  TOGGLE_BUTTON: "TOGGLE_BUTTON",
  NO_THEME_CHANGE: "NO_THEME_CHANGE",
  TOGGLE_STAKE_MODAL: "TOGGLE_STAKE_MODAL",

  //Form Inputs
  FILL_OUT_FORM: "FILL_OUT_FORM",

  //Clear object
  CLEAR_DATA: "CLEAR_DATA",

  //Staking
  ALL_STAKED: "ALL_STAKED",
  USER_STAKED: "USER_STAKED",
  ESTIMATED_REWARDS: "ESTIMATED_REWARDS",
  STAKING_TOKEN: "STAKING_TOKEN",
  UNSTAKING_TOKEN: "UNSTAKING_TOKEN",
  UNSTAKING_TOKEN_FAILED: "UNSTAKING_TOKEN_FAILED",
  STAKED_STATUS: "STAKED_STATUS",
  TOKEN_AVAILABLE_BALANCE: "TOKEN_AVAILABLE_BALANCE",
  PENDING_REWARDS: "PENDING_REWARDS",
  STAKING_TOKEN_FAILED: "STAKING_TOKEN_FAILED",
  CLAIMING_REWARDS: "CLAIMING_REWARDS",
  MY_STAKING_INFO: "MY_STAKING_INFO",
  TIME_LIMIT: "TIME_LIMIT",
  LOCK_PERIOD: "LOCK_PERIOD",

  // Market action
  MARKET_QUOTE: "MARKET_QUOTE",
  STAKING_SCORE: "STAKING_SCORE",
};
export default _const;
