import SmallModal from "../reusables/smallModal";
import { InfoMsg, InfoTitle } from "./stakestyle";
import { ButtonState } from "../reusables/button";
import { useSelector, useDispatch } from "react-redux";
import { getFormDetails } from "../methods/actions";

const Info = () => {
  const theme = useSelector((state) => state.General.theme);
  const dispatch = useDispatch();

  return (
    <SmallModal theme={theme}>
      <div>
        <div className="t-center mb-3">
          {!theme ? (
            <img src="/assets/lightInfo.svg" alt="info-icon" />
          ) : (
            <img src="/assets/darkinfo.svg" alt="info-icon" />
          )}
        </div>

        <div>
          {" "}
          <InfoTitle> Flexible Staking</InfoTitle>
          <InfoMsg>
            {" "}
            Flexible Staking allows you to stake any amount of WNT and redeem
            your reward after a 7days period of staking. You can top-up your WNT
            staking.{" "}
          </InfoMsg>
        </div>
        <div>
          <InfoTitle> Fixed Staking</InfoTitle>
          <InfoMsg>
            Fixed staking allows you to lock up your WNT Token for a set period
            of time i.e 1 month, 2 Months, e.t.c and redeem your WNT reward in a
            7day period. Note: you can't top up Fixed staking. to restake, claim
            your WNT reward after 7days of staking and Stake again with a
            different wallet. Fixed staking earns more rewards than flexible
            because of the higher staking score.
          </InfoMsg>
        </div>
        <div>
          <div className="t-center">
            <ButtonState
              fontSize={"0.8rem"}
              buttonClass={"secondary"}
              label={"Close"}
              onClick={() =>
                dispatch(
                  getFormDetails({
                    props: ["infoModal"],
                    value: false,
                  })
                )
              }
            />
          </div>
        </div>
      </div>
    </SmallModal>
  );
};

export default Info;
