import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../reusables/modal";
import { ButtonContainer, CenterContainer, Msg, Title } from "./statusstyle";
import { ButtonState } from "../reusables/button";
import { claimUserRewards } from "../methods/actions";
import Loader from "../reusables/loader";
import { numberWithCommaswithoutdecimals } from "../methods/helper";

const Claim = () => {
  const dispatch = useDispatch();
  const claimingRewards = useSelector(
    state => state.LoaderReducer.claimingRewards
  );
  const pendingUserRewards = useSelector(
    state => state.StakingReducer.pendingUserRewards
  );
  const tokenAddress = useSelector(state => state.General.tokenAddress);

  return (
    <Modal visible={true} width={"30%"} title={" "}>
      <div>
        <CenterContainer>
          <img src="/assets/claimicon.svg" assets="success" />
        </CenterContainer>
        <CenterContainer>
          <Title>
            {" "}
            You are claiming{" "}
            {numberWithCommaswithoutdecimals(pendingUserRewards)} WNT
          </Title>{" "}
          <Msg>
            You will not be able to claim rewards within 24 hours if you do
            this.
          </Msg>
        </CenterContainer>
        <ButtonContainer>
          <div className="col-lg-12 t-center ">
            {claimingRewards ? (
              <ButtonState
                fontSize={"0.8rem"}
                buttonClass={"secondary"}
                label={<Loader color={"#E5B910"} />}
              />
            ) : (
              <ButtonState
                fontSize={"0.8rem"}
                buttonClass={"secondary"}
                label={"Claim WNT now"}
                onClick={() => dispatch(claimUserRewards(tokenAddress))}
              />
            )}
          </div>
        </ButtonContainer>
      </div>
    </Modal>
  );
};

export default Claim;
