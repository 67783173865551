import React from "react";
import Modal from "../reusables/modal";
import {
  InstructionBox,
  Instruction,
  ButtonContainer,
  TitleContainer,
  Title,
  CurrentStakeContainer,
  Text,
  Value,
  ErrMsg,
} from "./unstakestyle";
import { ButtonState } from "../reusables/button";
import { unStakeAvailableToken } from "../methods/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../reusables/loader";
import moment from "moment";

const Unstake = () => {
  const dispatch = useDispatch();
  const unstakingToken = useSelector(
    (state) => state.LoaderReducer.unstakingToken
  );
  const tokenAddress = useSelector((state) => state.General.tokenAddress);
  const stakingInfo = useSelector((state) => state.StakingReducer.stakingInfo);
  const userStaked = useSelector((state) => state.StakingReducer.userStaked);
  const timeLimit = useSelector((state) => state.StakingReducer.timeLimit);
  const period = useSelector((state) => state.StakingReducer.period);
  const stakingPeriod = useSelector(
    (state) => state.StakingReducer.stakingPeriod
  );
  const unstakingFailedMsg = useSelector(
    (state) => state.StakingReducer.unstakingFailedMsg
  );
  const theme = useSelector((state) => state.General.theme);

  let timeBefore =
    (new Date(Number(stakingInfo[2])).getTime() +
      stakingPeriod[Number(stakingInfo[3])]) *
    1000;
  return (
    <Modal
      title={
        <React.Fragment>
          {" "}
          {!theme ? (
            <img
              src="/assets/unstakelightthemeicon.svg"
              alt={"stake-icon"}
              className={"mr-1"}
            />
          ) : (
            <img
              src="/assets/unstakedarkthemeicon.svg"
              alt={"stake-icon"}
              className={"mr-1"}
            />
          )}{" "}
          UnStake
        </React.Fragment>
      }
      visible={true}
      width={"30%"}
    >
      <TitleContainer>
        <Title> Are you sure you want to unstake? </Title>
      </TitleContainer>
      <CurrentStakeContainer>
        <Text> Current Unstake Return</Text>
        <Value> {userStaked.toFixed(2)}WNT</Value>
      </CurrentStakeContainer>
      {stakingInfo[3] === "0" ? null : (
        <CurrentStakeContainer>
          <Text> Lock Period</Text>
          <Text> {moment(timeBefore).endOf("day").fromNow()}</Text>
        </CurrentStakeContainer>
      )}

      <div>
        <InstructionBox>
          <Instruction>
            Note: Unstaking before the end of lock duration may lead to loss of
            earned score.
          </Instruction>
        </InstructionBox>
        {unstakingFailedMsg ? (
          <ErrMsg> {unstakingFailedMsg}</ErrMsg>
        ) : (
          <div> </div>
        )}
        {unstakingToken ? (
          <ButtonContainer>
            <div className="col-lg-12 t-center ">
              <ButtonState
                fontSize={"0.8rem"}
                buttonClass="secondary"
                label={<Loader color={"#E5B910"} />}
              />
            </div>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <div className="col-lg-12 t-center ">
              <ButtonState
                fontSize={"0.8rem"}
                buttonClass="secondary"
                label={"Unstake"}
                onClick={() =>
                  dispatch(
                    unStakeAvailableToken(
                      tokenAddress,
                      stakingInfo[2],
                      timeLimit,
                      period,
                      timeBefore
                    )
                  )
                }
              />
            </div>
          </ButtonContainer>
        )}
      </div>
    </Modal>
  );
};

export default Unstake;
