import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { lighttheme, darktheme } from "./theme";
import NavBar from "./components/partials/navbar";
import Footer from "./components/partials/footer";
import ConnectionModal from "./components/wallets/connectionmodal";
import { recreateWeb3 } from "./components/methods/utils/useAuth";
import { reinitializeApp } from "./components/methods/actions";
import Notifications from "react-notify-toast";

import Router from "./Router";

const Themeing = () => {
  let dispatch = useDispatch();

  const theme = useSelector(state => state.General.theme);
  const tokenAddress = useSelector(state => state.General.tokenAddress);

  useEffect(
    () => {
      // localStorage.setItem("userthemeswitch", JSON.stringify(theme));
      dispatch(recreateWeb3(tokenAddress));
      dispatch(reinitializeApp());
    },
    [dispatch],
    theme
  );

  const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.mainColor};
    font-family: "Poppins", sans-serif !important;
  }`;
  return (
    <ThemeProvider theme={theme ? darktheme : lighttheme}>
      <GlobalStyle />
      <Notifications />
      <NavBar />
      <Router />
      <ConnectionModal />
      <Footer />
    </ThemeProvider>
  );
};

export default Themeing;
